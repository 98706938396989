import React from "react";
import Footerlogo from "../assets/images/footerlogo.svg";

import FacebookLogo from "../assets/images/facebookLogo.svg";
import linkedInLogo from "../assets/images/linkedinIcon.svg";
import TwitterLogo from "../assets/images/twitterIcon.svg";
import YoutubeLogo from "../assets/images/youtubeIcon.svg";
import SkypeLogo from "../assets/images/skypeIcon.svg";

import linkedHoverLogo from "../assets/images/linkedHovericon.svg";
import FacebookHoverLogo from "../assets/images/FacebookHoverLogo.svg";
import TwitterHoverLogo from "../assets/images/TwitterHoverLogo.svg";
import YoutubeHoverLogo from "../assets/images/YoutubeHoverLogo.svg";
import SkypeHoverLogo from "../assets/images/SkypeHoverLogo.svg";

import image1 from "../assets/images/image 1.svg";
import image2 from "../assets/images/image 2.svg";
import image3 from "../assets/images/image 3.svg";
import image4 from "../assets/images/image 4.svg";
import image5 from "../assets/images/image 5.svg";
import image6 from "../assets/images/image 6.svg";

import "./Footer.css";
import { useNavigate } from "react-router-dom";
import { colors } from "../global/Colors";

const Footer = () => {
  const navigate = useNavigate();

  const iconSize = 16;

  const navigateToPage = (pageName) => {
    switch (pageName) {
      case "ManagedSecurityServices":
        navigate("/managed-security-services");
        window.scrollTo(0, 0);
        break;
      case "SOCService":
        navigate("/soc-service/");
        window.scrollTo(0, 0);

        break;
      case "PenetrationTesting":
        navigate("/penetration-testing/");
        window.scrollTo(0, 0);

        break;
      case "SecurityComplianceAndAuditing":
        navigate("/security-compliance-and-auditing/");
        window.scrollTo(0, 0);

        break;
      case "EndpointSecurityServices":
        navigate("/endpoint-security-services/");
        window.scrollTo(0, 0);

        break;
      case "NetworkSecurityManagement":
        navigate("/network-security-management/");
        window.scrollTo(0, 0);

        break;
      case "SecurityEngineering":
        navigate("/security-engineering/");
        window.scrollTo(0, 0);

        break;
      case "SecurityAwarenessProgram":
        navigate("/security-awareness-program/");
        window.scrollTo(0, 0);

        break;
      case "MalwareAnalysis":
        navigate("/malware-analysis/");
        window.scrollTo(0, 0);

        break;
      case "CaseStudies":
        navigate("/case-studies/");
        window.scrollTo(0, 0);

        break;
      case "AboutUs":
        navigate("/about-us");
        window.scrollTo(0, 0);

        break;
      case "ContactUs":
        navigate("/contact-us/");
        window.scrollTo(0, 0);

        break;

      case "Home":
        navigate("/");
        window.scrollTo(0, 0);
        break;
      case "PrivacyPolicy":
        navigate("/privacy-policy/");
        window.scrollTo(0, 0);
        break;
      case "TermsAndConditions":
        navigate("/terms-and-conditions/");
        window.scrollTo(0, 0);
        break;
      default:
        console.warn("Invalid page name provided");
        break;
    }
  };

  const socialLinks = [
    {
      icon: linkedInLogo,
      hoverIcon: linkedHoverLogo,
      links: "https://www.linkedin.com/company/cloudshield-ai",
    },

    // {
    //   icon: FacebookLogo,
    //   hoverIcon: FacebookHoverLogo,
    //   links: "",
    // },
    // {
    //   icon: TwitterLogo,
    //   hoverIcon: TwitterHoverLogo,
    //   links: "",
    // },
    // {
    //   icon: YoutubeLogo,
    //   hoverIcon: YoutubeHoverLogo,
    //   links: "",
    // },
    // {
    //   icon: SkypeLogo,
    //   hoverIcon: SkypeHoverLogo,
    //   links: "",
    // },
  ];

  const iconList = [
    {
      icon: image1,
      links: "",
    },
    {
      icon: image2,
      links: "",
    },
    {
      icon: image3,
      links: "",
    },
    {
      icon: image4,
      links: "",
    },
    {
      icon: image5,
      links: "",
    },
    {
      icon: image6,
      links: "",
    },
  ];
  return (
    <>
      <footer className="Footer pt-5">
        <div className="container">
          <section className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 pe-lg-4 text-center text-lg-start text-md-start">
                <img
                  style={{ height: "34px", cursor: "pointer" }}
                  src={Footerlogo}
                  className="img-fluid mb-4"
                  alt="Footerlogo"
                  onClick={() => {
                    navigateToPage("Home");
                  }}
                />
                <br />
                <div>
                  <a
                    href="tel:+1 713 213 2908"
                    className=" font-face-Roboto-Regular text-white"
                    style={{ textDecoration: "none" }}
                  >
                    +1-713-213-2908
                  </a>
                </div>
                <br />
                <div>
                  <a
                    href="mailto:secure@cloudshield.ai"
                    className="font-face-Roboto-Regular text-white"
                    style={{ textDecoration: "none" }}
                  >
                    secure@cloudshield.ai
                  </a>
                </div>
                <a
                  href="https://www.google.com/maps/search/+3200+Wilcrest+Dr+%23170,+%3Cbr%3E%3C%2Fbr%3E+Houston,+TX+77042,+United+States/@29.7286226,-95.5749869,17z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-face-Roboto-Regular mt-3"
                  style={{ color: "#d4d6d7b4", textDecoration: "none" }}
                >
                  <p
                    className="font-face-Roboto-Regular mt-3"
                    style={{ color: "#d4d6d7b4" }}
                  >
                    3200 Wilcrest Dr #170, <br></br> Houston, TX 77042, United
                    States
                  </p>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 text-center text-lg-start text-md-start">
                <h5 className="mt-1 mb-3 font-face-Raleway-Bold">
                  Useful links
                </h5>

                <ul className="f-menu">
                  <li
                    onClick={() => {
                      navigateToPage("ManagedSecurityServices");
                    }}
                  >
                    Managed Security Services
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("SOCService");
                    }}
                  >
                    SOC as a Service
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("PenetrationTesting");
                    }}
                  >
                    Penetration Testing
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("SecurityComplianceAndAuditing");
                    }}
                  >
                    Security Compliance And Auditing
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("EndpointSecurityServices");
                    }}
                  >
                    Endpoint Security Services
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("NetworkSecurityManagement");
                    }}
                  >
                    Network Security Management
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("SecurityEngineering");
                    }}
                  >
                    Security Engineering
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("SecurityAwarenessProgram");
                    }}
                  >
                    Security Awareness Program{" "}
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("MalwareAnalysis");
                    }}
                  >
                    Malware Analysis
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 text-center text-lg-start text-md-start">
                <h5 className="mt-1 mb-3 font-face-Raleway-Bold">
                  Quick Links
                </h5>

                <ul className="mb-4 f-menu">
                  <li
                    onClick={() => {
                      navigateToPage("AboutUs");
                    }}
                  >
                    About Us
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("CaseStudies");
                    }}
                  >
                    Case Studies{" "}
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("ContactUs");
                    }}
                  >
                    Contact Us
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("PrivacyPolicy");
                    }}
                  >
                    Privacy Policy{" "}
                  </li>
                  <li
                    onClick={() => {
                      navigateToPage("TermsAndConditions");
                    }}
                  >
                    Terms and Conditions
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 text-center text-lg-start text-md-start">
                <h5 className="mt-1 font-face-Raleway-Bold">Follow Us:</h5>

                <div className="d-flex justify-content-center justify-content-lg-start justify-content-md-start">
                  {socialLinks?.map((x, index) => {
                    return (
                      <div key={index} className="me-4 mt-2">
                        <a
                          href={x.links}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="icon-img"
                            src={x.icon}
                            alt="icon-img"
                            onMouseOver={(e) =>
                              (e.currentTarget.src = x.hoverIcon)
                            }
                            onMouseOut={(e) => (e.currentTarget.src = x.icon)}
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="text-center py-4">
          <div
            style={{
              borderTop: "1px solid #d4d6d7",
              paddingTop: "20px",
              color: colors.lightsilver,
            }}
            className="container"
          >
            © Copyright 2024. All rights reserved.{" "}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
