import React, { useEffect, useState } from "react";
import "./ContactUsForm.css";
import CustomButton from "./CustomButton";
import LocationIcon from "../../src/assets/images/lctn.svg";
import PhoneIcon from "../../src/assets/images/call.svg";
import EmailIcon from "../../src/assets/images/msg.svg";
import Aos from "aos";
import ThankYouModal from "./ThankYouModal";
import { useNavigate } from "react-router-dom";

const ContactUsForm = () => {
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [notes, setNotes] = useState("");
  const [subject, setSubject] = useState("");

  const handleInputChange = (e) => {
    const originalValue = e.target.value;
    const cleanedValue = originalValue.replace(/\D+/g, ""); // Remove non-digits
    setPhoneNumber(cleanedValue); // Update state with cleaned value
    e.target.value = cleanedValue; // Update input field display
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailInput = document.getElementById("email");
    const nameInput = document.getElementById("name");

    const phoneInput = document.getElementById("phone");
    const subjectInput = document.getElementById("subject");
    const messageInput = document.getElementById("message");

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (
      emailInput.value === "" ||
      nameInput.value === "" ||
      phoneInput.value === ""
    ) {
      alert("Please enter all required fields.");
      return;
    }

    if (!emailPattern.test(emailInput.value)) {
      alert("Please enter a valid email address.");
      return;
    }

    const response = await fetch(
      "https://cloudshield-backend.vercel.app/api/email/V1/contactInfoMail",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userName: name,
          userEmail: email,
          userPhoneNumber: phoneNumber,
          userSubject: subject,
          userMessage: notes,
        }),
      }
    );
    const responseData = await response.json();

    console.log(responseData.msg, "check");
    document.getElementById("myForm").reset();

    setModalShow(true);
  };
  const contacts = [
    // {
    //   icon: PhoneIcon,
    //   details: "Have any question?",
    //   value: "+17 132  132 908",
    //   link: "tel:+17132132908",
    // },
    {
      icon: EmailIcon,
      // details: "Write email",
      value: "secure@cloudshield.ai",
      link: "mailto:secure@cloudshield.ai",
    },
    {
      icon: LocationIcon,
      // details: "Visit anytime",
      value: "3200 Wilcrest Dr #170, Houston, TX 77042, United States",
      link: "https://www.google.com/maps/place/3200+Wilcrest+Dr+%23170,+Houston,+TX+77042,+USA/@29.728618,-95.5749869,17z/data=!3m1!4b1!4m6!3m5!1s0x8640dcdaa121f1e9:0xac08d6f2300c1912!8m2!3d29.728618!4d-95.572412!16s%2Fg%2F11lk_nk9jg?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D",
    },
  ];
  return (
    <div className="contact-main mt-5">
      <div className="container py-5">
        <div className="text-center">
          {/* <p
            className="text-white font-face-Raleway-Bold"
            data-aos="zoom-in"
            data-aos-duration="1900"
          >
            Let’s Talk
          </p> */}
          <h1
            className="text-white font-face-Raleway-Bold"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            Get in touch with us
          </h1>
          {/* <p data-aos="zoom-in" data-aos-duration="2100">
            Talk with our professional
          </p> */}
        </div>

        <div className="row pt-5">
          <div className="d-flex flex-column flex-lg-row flex-md-row justify-content-center gap ">
            {contacts?.map((x, index) => {
              return (
                <div class="d-flex justify-content-lg-center">
                  {" "}
                  <div>
                    {" "}
                    <img src={x.icon} alt="img" style={{ width: "50px" }} />
                  </div>
                  <p className="mt-3 ms-2">
                    {/* {x.details}
                    <br></br> */}
                    <a
                      href={x.link}
                      className="text-white"
                      target={x.link.startsWith("mailto:") ? "_self" : "_blank"}
                      rel={
                        x.link.startsWith("mailto:")
                          ? ""
                          : "noopener noreferrer"
                      }
                      style={{ textDecoration: "none" }}
                    >
                      {x.value}
                    </a>{" "}
                  </p>
                </div>
              );
            })}
          </div>

          <div className="col-md-8 mx-auto mt-4 text-center">
            {/* <p
              className="text-white font-face-Raleway-Bold"
              data-aos="zoom-in"
              data-aos-duration="1900"
            >
              Contact Us
            </p> */}
            {/* <h1
              className="text-white font-face-Raleway-Bold"
              data-aos="zoom-in"
              data-aos-duration="1900"
            >
              Request A Call Back !
            </h1> */}
            <form onSubmit={handleSubmit} id="myForm">
              <div className="input-views">
                <input
                  className="name-input font-face-Montserrat-Regular"
                  placeholder="Your Name"
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  className="email-input font-face-Montserrat-Regular"
                  placeholder="Email Address"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input-views">
                <input
                  className="phone-input font-face-Montserrat-Regular"
                  placeholder="Phone Number"
                  id="phone"
                  onChange={handleInputChange}
                />
                <input
                  className="subject-input font-face-Montserrat-Regular"
                  placeholder="Subject"
                  id="subject"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <textarea
                className="w-100 font-face-Montserrat-Regular"
                placeholder="Message"
                rows={4}
                id="message"
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>

              <div className="mt-3 tick-box">
                {" "}
                <CustomButton btnText="Submit" />
              </div>
            </form>
            <ThankYouModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              name={name}
              heading="Thank you for contacting us!"
              paraOne=" Thank you for getting in touch with us! We’ve received your
              message and our team will review it shortly. Whether you’re
              looking to enhance your cloud security, streamline your
              infrastructure, or have specific questions about our services, we
              are here to help."
              paraTwo="In the meantime, feel free to explore more about Cloud Shield and
              how we can safeguard your data and digital assets. We’re excited
              to assist you in securing your cloud environment and ensuring your
              peace of mind."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
